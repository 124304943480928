import React from 'react'
import styles from './styles.module.css'
const Logo = React.lazy(() => import('../../components/logo'));

const Wait: React.FC = () => {
        
    return <>
                
                <header className={styles.wrapper}>
                    <Logo />
                </header>

                <section className={styles.section}>
                    <h5>Em breve...</h5>
                </section>

    </>
}

export default Wait