import React, { Suspense } from 'react'
import Loading from '../../components/loading';
import styles from './styles.module.css';
const Logo = React.lazy(() => import('../../components/logo'));
const Menu = React.lazy(() => import('../../components/menu'));
const Social = React.lazy(() => import('../../components/social'));

const Home: React.FC = () => {

    const [loading, setLoading] = React.useState<boolean>(true)

    const handleLoading = (): void => {
        setTimeout(() => {
            document.location.href = 'https://escoladafamilia.institutojef.org.br';
            // setLoading(false);
        }, 2000)
    }

    React.useEffect(() => handleLoading(), [])

    return <>
        <Suspense fallback={<Loading />}>
            {loading ? <Loading /> : <>
                <header className={styles.wrapper}>
                    {/* <Logo /> */}
                </header>

                <section className={styles.section}>
                    <Menu />
                    <Social />
                </section>
            </>}
        </Suspense>
    </>
}

(function(){
    let deferredPrompt:any;
    // const addBtn = document.getElementById('test')!;

    // console.log(addBtn);

    // addBtn.style.display = 'none';
    
    window.addEventListener('beforeinstallprompt', (e) => {
        // Prevent Chrome 67 and earlier from automatically showing the prompt
        // Stash the event so it can be triggered later.
        deferredPrompt = e;
        // Update UI to notify the user they can add to home screen
        deferredPrompt.prompt();
        // Wait for the user to respond to the prompt
        deferredPrompt.userChoice.then((choiceResult:any) => {
            if (choiceResult.outcome === 'accepted') {
            } else {
            }
            deferredPrompt = null;
        });
    });
})();
    

export default Home